import { useEffect } from 'react';
import { useQuery as useGraphQLQuery } from '@apollo/client';
import { CHECK_LOGGED_IN } from '../../../api/User/graph';
import { AccountsContainer } from '../styles';
import { reloadOrRedirectBasedOnQueryParam } from '@thestudentroom/lib.themes';
import loadable from '@loadable/component';
import { getRegPromptTitle } from '../../../helpers/getRegPromptTitle';

const Accounts = loadable.lib(() => import('@thestudentroom/package.accounts'));

type LoginPageProps = {
    googleClientId?: string;
    facebookClientId?: string;
    appleClientId?: string;
    requestParams: any;
};

export default function LoginPage(props: LoginPageProps) {
    const route = props.requestParams?.route ?? 'login-page';
    const { data } = useGraphQLQuery(CHECK_LOGGED_IN);

    useEffect(() => {
        if (data && data.checkLoggedIn > 0) {
            reloadOrRedirectBasedOnQueryParam();
        }
    }, []);

    if (
        typeof window !== 'undefined' &&
        typeof window.FB !== 'undefined' &&
        props.facebookClientId
    ) {
        const loadFacebook = function () {
            window.FB &&
                window.FB.init({
                    appId: props.facebookClientId,
                    status: false,
                    cookie: true,
                    xfbml: true,
                    version: 'v16.0'
                });
        };

        setTimeout(() => loadFacebook(), 1000);
    }

    return (
        <>
            {data && data.checkLoggedIn === 0 && (
                <AccountsContainer data-testid="login-page">
                    <Accounts fallback={<></>}>
                        {({ AccountsForm }) => (
                            <AccountsForm
                                {...props}
                                intialType="login"
                                route={route}
                                website="TSR"
                                registerTitle={getRegPromptTitle(route).register}
                                loginTitle={getRegPromptTitle(route).login}
                                afterRegister={() => {
                                    reloadOrRedirectBasedOnQueryParam();
                                }}
                            />
                        )}
                    </Accounts>
                </AccountsContainer>
            )}
        </>
    );
}
